

body {
  margin: 0;
  font-family: 'Archive', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

section{
  max-width: 1250px;
}

.our-p:hover{
display: block;
opacity: 1;
}

.our-p{
  opacity: 0;
}

.con img{
  display: inline;
  margin-right: 5px;
}

.con p{
  display: inline;
}



.anmation-loader{

 
  animation: loader 4s linear infinite;

}


@keyframes loader{
 0%{
  
  transform: scale3d(1, 1, 1);
  }
 
  50%{
  
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100%{
    
    transform: scale3d(1, 1, 1);
  }
 
}


.support-card:hover{
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  transition: 0.5s;
  background-color:#AD9357;
  color: #222 !important;
}
.support-card2:hover{
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  transition: 0.5s;
  background-color:#303330;
  color: #AD9357 !important;
}

.coco{
  background-color: #222 !important;
  color: #AD9357 !important;
}

.coco2{
  background-color: #cab073 !important;
  color: #222 !important;
}

.select-ser:focus {
  outline: none;

}



