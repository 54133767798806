nav {
   
     height: 60px;
     
   
     display: flex;
     align-items: center;
     font-weight: 600;
     
   
     
 }

 .nav_link{
     display: flex;
     justify-content: space-between;
     align-items: center;
     
     
 }
 .div-con{
    padding: 0px 30px;
    margin: 20px 10px;
 }
 
 .medlle-screen{
     display: none;
 }
 
 
 
 .dropdown{
     background-color: black;
     width: 20px;
     height: 2.4px;
     border-radius: 50px;
     right: 10px;
     position:absolute;
     
     
 
 
 }
 
 .open .dropdown{
     
     margin: 0px 8px;
    
 }
 
 
 .open .dropdown1{
     transform: rotate(45deg);
 
 }
 
 .open .dropdown2{
     transform: rotate(-45deg);
 }
 
 .close{
     position: absolute;
        right: 1px;
        top: 20px;
     
 }
 
 .close .dropdown{
  position:relative;
     margin: 4px 0px;
 }
 
 
 
 @media only screen and (max-width: 700px) {
     .nav_link{display:none;}
     .medlle-screen{
         display: flex;
     }
 
     nav{
         padding: 10px;
     }

     .lang-xl{
        display: none;
     }
 
 
 
 }
 
 nav ul{
     background-color: rgba(255, 255, 255, 0.274);
     backdrop-filter:blur(10px);
     position: absolute;
     top: 60px;
     right: 20px;
     width: 150px;
     padding: 0;
 }
 
 nav li{
     list-style: none;
     display: flex;
     border: rgba(0, 0, 0, 0.075) solid 0.5px;
     align-items: center;
     justify-content: center;
     height: 50px;
     background-color: rgba(255, 255, 255, 0.733);
     backdrop-filter:blur(200px);
 }
 
 .link-nav{
     text-decoration: none;
    
     color: #AD9357;
     
 }
 .link-nav:hover{
     color:#FAA91C;
     transform: scale(110%);
 
 }
 .active{
     
     color: #303330;
     padding:4px 20px;
     border-radius: 0px 90px;
     padding-bottom: 5px;
 }